/** @jsx jsx */
import { DUIText } from "@alch/ui";
import { Link } from "gatsby";
import { memo, ReactElement } from "react";
import { BoxProps, jsx } from "theme-ui";

import Section from "./Section";
import { linkSx } from "./styles";

export interface ErrorViewProps extends BoxProps {
  title: string;
  message: string;
}

const ErrorView = memo(function ErrorView({
  title,
  message,
  ...boxProps
}: ErrorViewProps): ReactElement {
  return (
    <Section {...boxProps} sx={{ color: "danger.core" }} centered={true}>
      <DUIText size="2xl">{title}</DUIText>
      <DUIText
        size="md"
        mt={2}
        sx={{ maxWidth: "300px", color: "danger.dark" }}
      >
        {message}
      </DUIText>
      <DUIText mt={5} sx={{ textAlign: "center", fontWeight: 500 }} size="md">
        <Link sx={linkSx} to="/">
          Return to Login
        </Link>
      </DUIText>
    </Section>
  );
});
export default ErrorView;
