/** @jsx jsx */
import { memo, ReactElement } from "react";
import { jsx } from "theme-ui";

import ErrorView from "../components/ErrorView";
import MasterLayout from "../components/MasterLayout";

const NotFoundPage = memo(function NotFoundPage(): ReactElement {
  return (
    <MasterLayout title="Not Found">
      <ErrorView
        title="404 Not Found"
        message="This page doesn't seem to exist…"
      />
    </MasterLayout>
  );
});
export default NotFoundPage;
